import {Injectable} from '@angular/core';
import {EMPTY, Observable, of, throwError} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {API_URL} from 'src/app/pathConfig';
import {GlobalService} from '../../services/global.service';
import {ApiResponseService} from 'src/app/services/api-response.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    /** exception URLs on which the alert won't trigger */
    errorExceptionRegex = new RegExp(/yardi\/getYardiConfigs/);

    errorExceptionRegexForLogs = new RegExp(/yardi\/getXmlLog.*/);
    rateLimiting = false;

    /** constructor */
    constructor(private readonly global: GlobalService, private apiResponseService: ApiResponseService) {}

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * token interceptor which adds the authentication token on every ongoing node-api request
     */
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (this.rateLimiting) {
            return EMPTY;
        }
        // this condition will make sure not to append token on php apis
        if (!req.url.includes('api')) {
            return next.handle(req);
        }

        const modifiedReq = this.setToken(req);
        return next.handle(modifiedReq).pipe(
            map((response) => response),
            catchError(this.handleError)
        );
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * append the auth token on http request header only if the token exists in the local storage
     *
     * @returns modified request with auth token req header
     */
    private setToken(req: HttpRequest<any>): HttpRequest<any> {
        const tempToken = this.global.getLocalStorage('tenantpayToken');

        // If the content type is set, it must be a PHP server API and should not be touched
        if (req.headers.get('Content-Type')) {
            return req;
        }

        // If the token does not exist in local storage, return the original request
        if (tempToken === 'false' || tempToken === '') {
            return req;
        }

        // Check if the URL contains 'mfa/verify/phone-number'
        if (req.url.includes('mfa/verify/phone-number')) {
            return req.clone({
                headers: new HttpHeaders({
                    Authorization: `Bearer ${tempToken}`,
                    origin: API_URL, // Set the Origin header
                }),
                withCredentials: true, // Include credentials for this request
            });
        }

        // Default behavior for other requests
        return req.clone({
            headers: new HttpHeaders({
                Authorization: `Bearer ${tempToken}`,
            }),
            withCredentials: true,
        });
    }

    /**
     * handles the error handling in api failing
     */
    private handleError = (errorResponse: HttpErrorResponse) => {
        const {error, url} = errorResponse;

        if (this.errorExceptionRegex.test(url)) {
            return of(error);
        }

        /**In case of getting yardi transactions xml logs if there is error of not found we check if the user is trying to obtain the logs older than 30 days and show messgage accordingly */
        if (url.includes('login/token')) {
            this.global.closeModal();
            this.global.logoutUser();
        }
        if (this.errorExceptionRegexForLogs.test(url)) {
            if (errorResponse.status === 404) {
                const transactionDateString = this.global.yardiTransactionDateForLog;
                const transactionDate = new Date(transactionDateString);
                const currentDate = new Date();
                const daysDifference = Math.floor((currentDate.getTime() - transactionDate.getTime()) / (1000 * 60 * 60 * 24));
                if (daysDifference > 30) {
                    this.global.basicAlert('', 'label.log_removed', true);
                }
                return of(error);
            }
        }
        if (error.message === 'User does not exist') {
            this.global.basicAlert('', 'label.user_does_not_exist', true);
        } else if (error.message === 'Unauthorized') {
            this.global.checkMfa().subscribe((isMfaRequired) => {
                if (isMfaRequired) {
                    return this.checkToken().pipe(catchError(() => throwError(error)));
                } else {
                    this.global.closeModal();
                    this.global.logoutUser();
                    this.global.basicAlert('', 'errors.unauthorized', true);
                }
            });
        } else if (url.includes('getYardiTransactions')) {
            this.global.yardiLoading = false;
            this.global.hideLoading();
            this.displayBadRequestMessage(error.message);
        } else if (errorResponse.status === 400) {
            this.displayBadRequestMessage(error.message);
        } else if (errorResponse.status === 404 && error.message == 'company_info_not_found') {
            // this is for case when kyc id isn't created and user is new
        } else if (errorResponse.status === 404 && url.includes('company-setting')) {
        } else if (errorResponse.status === 404 && url.includes('accountStatus')) {
        } else if (errorResponse.status === 404 && url.includes('terms-and-conditions')) {
            throw errorResponse;
        } else if (errorResponse.status === 404) {
            this.global.basicAlert('', errorResponse.statusText, false);
        } else if (errorResponse.status === 401 || errorResponse.status === 409) {
            this.global.basicAlert('', `label.${error.message}`, true);
        } else if (errorResponse.status === 403 && error.message === 'Forbidden resource') {
            this.global.yardiLoading = false;
            this.global.hideLoading();
            this.global.basicAlert('', 'Not authorized', true);
        } else if (errorResponse.status === 403 && error.message === 'password_reset_required') {
            this.global.basicAlert('Password reset required', 'The Initial Password and Login Link are sent to your provided email. Please reset it.');
        } else if (errorResponse.status === 403 && error.message === 'user_id_not_provided') {
        } else if (errorResponse.status === 429) {
            this.global.hideLoading();
            this.global.basicAlert('', 'label.too_many_requests', true);
            this.global.showLoading();
            this.rateLimiting = true;
            setTimeout(() => {
                this.global.hideLoading();
                this.rateLimiting = false;
                window.location.reload();
            }, 10000);
            return EMPTY;
        } else if (errorResponse.status === 403 && error.message === 'account_locked') {
            this.global.basicAlert('', 'label.account_locked', true);
        } else if (!errorResponse.status) {
            this.global.hideLoading();
            this.rateLimiting = true;
            this.global.basicAlert('', 'label.too_many_requests', true);
            this.global.showLoading();
            setTimeout(() => {
                this.global.hideLoading();
                this.rateLimiting = false;
                window.location.reload();
            }, 10000);

            return EMPTY;
        } else {
            this.global.basicAlert('', 'errors.something_went_wrong', true);
        }
        return of(error);
    };

    displayBadRequestMessage(message) {
        this.global.basicAlert('', `label.${message}`, true);
    }

    checkToken(): Observable<void> {
        return this.apiResponseService.loginToken().pipe(
            tap((response) => {
                if (response?.data?.loginToken) {
                    this.global.setLocalStorage('tenantpayToken', response?.data?.loginToken);
                } else {
                    this.global.closeModal();
                    this.global.logoutUser();
                    this.global.basicAlert('', 'errors.unauthorized', true);
                }
            }),
            catchError((error) => {
                this.global.closeModal();
                this.global.logoutUser();
                this.global.basicAlert('', 'errors.unauthorized', true);
                return throwError(error);
            })
        );
    }
}
